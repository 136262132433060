@import url('https://fonts.googleapis.com/css2?family=The+Girl+Next+Door&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
@font-face {
    font-family:'Mulish';
    src: local('Mulish'), url(./fonts/mulish.ttf) format('ttf');
}
}

.tricks_title {
    font-family: 'The Girl Next Door', cursive;
}

